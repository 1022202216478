import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Alert, Button } from 'react-bootstrap';
import AppContext from 'context/Context';
import ConfirmMail from 'pages/common/modals/ConfirmMail';
import GreetingCard from '../GreetingCard';
import UserProgress from './UserProgress';
import VideoCarousel from './VideoCarousel';
import SupportInfo from './SupportInfo';
import ManifestCover from './ManifestCover';
import { useHistory } from 'react-router-dom';
import DoKYC from 'pages/common/modals/DoKYC';
import AddKeys from 'pages/common/modals/AddKeys';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import { trackEvent } from 'services/externals/mixpanel';
import { breakpoints, capitalize } from 'helpers/utils';
import { Link } from 'react-router-dom';
import { Wallet as textLang } from 'staticData/languages';
import Flex from 'components/common/Flex';
import BannerAlert from '../BannerAlert';
import FeedbackModal from '../modals/FeedbackModal';
// import { getFromLocalStorage, teamLevel } from 'staticData/common';

const index = ({
  getInfo,
  completionInfo,
  todoList,
  ConfirmMailShow,
  setConfirmMailShow,
  doKYCShow,
  setDoKYCShow,
  addKeysShow,
  setAddKeysShow
}) => {
  const {
    config: { lang },
    userInfos,
    setUserInfos
  } = useContext(AppContext);

  const [canStartTrial, setCanStartTrial] = useState(false);
  const [fastAPiError, setFastApiError] = useState('');
  const [feedbackModalShow, setFeedbackModalShow] = useState(false);
  var feedbackModalTimeout;
  const routerHistory = useHistory();
  const queryParameters = new URLSearchParams(window.location.search);
  const fastApiConnected = queryParameters.get('fast_api') === 'connected';
  const error_id = queryParameters.get('error_id');
  const typeUrl = queryParameters.get('type');
  const kyc_accepted_status = ['approved', 'contact_support', 'processing'];
  if (!typeUrl) {
    queryParameters.set('type', 'onboard');
    routerHistory.push(`?${queryParameters.toString()}`);
  }
  const getStep = () => {
    var returnValue;
    if (!userInfos.email_confirmed) returnValue = 0;
    else if (!kyc_accepted_status.includes(userInfos.kyc_status))
      returnValue = 1;
    else if (!userInfos.connected_exchanges.includes('binance'))
      returnValue = 2;
    else returnValue = 3;
    return returnValue;
  };
  const [step, setStep] = useState(getStep());

  const walletMapData = {
    Binance: {
      guideLink: '/connect-binance-wallet',
      depositGuide: '/deposit-binance',
      convertGuide: '/convert-on-binance',
      available: true,
      userCanContact: true
    }
  };

  const getNextAction = info => {
    // It assumes that actionsOrder is available in the scope or passed as a parameter
    for (let key of todoList) {
      if (!info[key.id].value) {
        // Found the first unmet criterion, return its action and description
        return {
          action: info[key.id].action,
          task: key.task,
          description: key.description,
          id: key.id
        };
      }
    }
    return null;
  };

  const handleSetKYCStatus = kycStatus => {
    setUserInfos({ ...userInfos, kyc_status: kycStatus });
  };

  const getVirtualTrial = async () => {
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'virtual_trial',
      httpMethod: 'get',
      usingCredentials: true,
      showError: false
    });
    if (resp.validResponse) {
      if (resp.data.can_start_trial)
        setCanStartTrial(true && !userInfos.isInCluster);
    }
  };

  // const shouldOpenModalFeedback = () => {
  //   const storedFeedback = getFromLocalStorage('feedbackQuestions');

  //   // If there is no stored feedback and the user level is below teamLevel, return true
  //   if (!storedFeedback && userInfos.level < teamLevel) return true;
  //   // If stored feedback exists and the user level is below teamLevel
  //   else if (storedFeedback && userInfos.level < teamLevel) {
  //     const now = new Date(); // Get the current date
  //     const lastOpenFeedbackModal = new Date(storedFeedback.ts); // Parse the stored timestamp
  //     // Calculate the difference in time in days
  //     const timeDifference = now - lastOpenFeedbackModal; // Difference in milliseconds
  //     // Convert milliseconds to days
  //     const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  //     // Return true if the last opened feedback modal is older than 3 days
  //     return daysDifference > 3;
  //   }
  //   // If none of the conditions are met, return false
  //   return false;
  // };

  // const checkOpenModals = () => {
  //   if (shouldOpenModalFeedback()) {
  //     feedbackModalTimeout = setTimeout(() => {
  //       setFeedbackModalShow(true);
  //     }, 2000); // 2 sec delay
  //   }
  // };

  useEffect(() => {
    getVirtualTrial();
    // track event fast API
    if (fastApiConnected || error_id) {
      trackEvent('exchangeKeys', {
        status_event: fastApiConnected ? 'add' : 'error',
        type: 'fastApi',
        error_id: error_id,
        page: window.location.pathname
      });
      if (error_id) setFastApiError(error_id);
      setAddKeysShow(true);
      queryParameters.delete('fast_api');
      routerHistory.push(`?${queryParameters.toString()}`);
    }
    // else checkOpenModals();

    return () => {
      clearTimeout(feedbackModalTimeout);
    };
  }, []);

  return (
    <>
      {userInfos && (
        <>
          <ConfirmMail
            ConfirmMailShow={ConfirmMailShow}
            setConfirmMailShow={setConfirmMailShow}
            step={0}
            withHeader={false}
          />
          <DoKYC
            doKYCShow={doKYCShow}
            setDoKYCShow={setDoKYCShow}
            step={step}
            setStep={setStep}
            setAddKeysShow={setAddKeysShow}
            setKycStatus={handleSetKYCStatus}
          />
          <AddKeys
            addKeysShow={addKeysShow}
            setAddKeysShow={setAddKeysShow}
            exchange={'Binance'}
            walletMapData={walletMapData}
            realoadUserInfo={getInfo}
            step={step}
            setStep={setStep}
            error_id={fastAPiError}
            connected={fastApiConnected}
          />
        </>
      )}
      <FeedbackModal
        showModal={feedbackModalShow}
        setShowModal={setFeedbackModalShow}
      />
      <Row className="mb-3">
        <Col xs={12}>
          <GreetingCard
            username={
              userInfos.kyc?.name
                ? capitalize(userInfos.kyc?.name)
                : userInfos.username
            }
            onboardCompleted={false}
          />
        </Col>
      </Row>
      <BannerAlert />
      <Row>
        <Col className="mb-3">
          <UserProgress
            info={completionInfo}
            getNextAction={getNextAction}
            todoList={todoList}
          />
        </Col>
      </Row>
      {canStartTrial && (
        <Alert variant="warning">
          <Row>
            <Col sm={8}>
              <h5
                dangerouslySetInnerHTML={{
                  __html:
                    userInfos.connected_exchanges.length > 0
                      ? textLang.startTrialTitle[lang]
                      : textLang.startTrialTitleKyc[lang]
                }}
              />
              <p
                className="mb-0"
                dangerouslySetInnerHTML={{
                  __html:
                    userInfos.connected_exchanges.length > 0
                      ? textLang.startTrialText[lang]
                      : textLang.startTrialTextKyc[lang]
                }}
              />
            </Col>
            <Col
              as={Flex}
              alignItems="center"
              justifyContent={
                window.innerWidth > breakpoints['sm'] ? 'end' : 'center'
              }
              className="mt-3 mt-sm-0"
            >
              <Button as={Link} variant="falcon-success" to="virtual-mode">
                {textLang.startTrialButton[lang]}
              </Button>
            </Col>
          </Row>
        </Alert>
      )}
      <Row>
        <Col className="mb-2">
          <VideoCarousel />
        </Col>
      </Row>
      <hr />
      <Row className="pt-2">
        <Col sm={6} className="mb-3">
          <ManifestCover />
        </Col>
        <Col sm={6} className="mb-3">
          <SupportInfo />
        </Col>
      </Row>
    </>
  );
};

export default index;
